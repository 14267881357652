import Container from 'react-bootstrap/Container';
import { Alert, Button, Card, Table, Modal, Accordion, Form } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "../ContentStyles";
import useBackend, {CallAPI, Loading, SpinnerX, Check} from '../../services/useBackend';
import React, { useState ,useEffect, useReducer, fetchData,useMemo} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {MaterialReactTable} from 'material-react-table';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, metaLookUpTitle} from '../../services/misc';
import { FaEdit, FaEraser, FaSortDown, FaSortUp, FaDownload, FaCheck } from 'react-icons/fa';
import CreateRequest from './createRequestComponent';

export default function Test(props) {
    const [saving,        setSaving]        = useState(false);
    const [resultVariant, setResultVariant] = useState('warning');
    const [resultText,    setResultText]    = useState('N/A');
    const [resultVisible, setResultVisible] = useState(false);  

    const [selectedWFData, setSelectedWFData] = useState({});

    const [addWFStepModalshow, setAddWFStepModalshow] = useState(false);
    const addWFStepInitModal = () => { setResultText(""); setResultVisible(false); return setAddWFStepModalshow(!addWFStepModalshow) }

    const [editWFStepModalshow, setEditWFStepModalshow] = useState(false);
    const editWFStepInitModal = () => { return setEditWFStepModalshow(!editWFStepModalshow) } 


    const [quickCloseStepModalshow, setQuickCloseStepModalshow] = useState(false);
    const quickCloseInitModal = () => { return setQuickCloseStepModalshow(!quickCloseStepModalshow) } 
    

    const [editDateModalshow, setEditDateModalshow] = useState(false);
    const editDateInitModal = () => { return setEditDateModalshow(!editDateModalshow) } 

    const [newRequestModalshow, setNewRequestModalshow] = useState(false);

    const WFStepAdd = async (event) => {
        setSaving(true);
        // setAddWFStepModalshow(false);	

        const AddWFForm = document.getElementById('AddWFForm');
        const formData = new FormData(AddWFForm);
        const formDataObj = Object.fromEntries(formData.entries());

        let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPI('/api/WorkFlow/'+props.RequestData.Guid, 'POST', JSON.stringify(formDataObj));
        props.setSelectedRequestData(jsondata.Request);
        

        setResultVariant("success")
        if (!errorsException) {
            setResultText("Created!");
            //document.getElementById("AddWFForm").reset();
            document.getElementById("newstep_misc01").value = '';
            document.getElementById("newstep_misc02").value = '';
            document.getElementById("newstep_misc03").value = '';
            document.getElementById("newstep_misc04").value = '';
            document.getElementById("newstep_misc05").value = '';            
        }
        setResultVisible(true);
        setSaving(false);
    }

    const WFStepUpdate = async (event) => {
        setEditWFStepModalshow(false);

        const EditWFForm = document.getElementById('EditWFForm');
        const formData = new FormData(EditWFForm);
        const formDataObj = Object.fromEntries(formData.entries());
        formDataObj.Date = ConcatLocalDateAndTime2ISO(document.getElementById('Date').value);

        let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPI('/api/WorkFlow/'+selectedWFData.Guid, 'PUT', JSON.stringify(formDataObj));
        props.setSelectedRequestData(jsondata.Request);
    }		

    const WFStepQuickClose = async (event) => {
        setEditWFStepModalshow(false);

        const EditWFForm = document.getElementById('QuickCloseForm');
        const formData = new FormData(EditWFForm);
        
        const formDataObj = Object.fromEntries(formData.entries());
        formDataObj.action = 'quickclose';
        console.log(JSON.stringify(formDataObj));

        let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPI('/api/WorkFlow/'+selectedWFData.Guid, 'PATCH', JSON.stringify(formDataObj));
        props.setSelectedRequestData(jsondata.Request);
        quickCloseInitModal();
    }		

    const WFStepRemove = async (event) => {
        const WFGuid = event.currentTarget.getAttribute("wfguid");
        const formDataObj = {action:'remove'};
        if (window.confirm("Are you sure?")) {
            let {resultVariant, resultVisible, resultText, jsondata} = await CallAPI('/api/WorkFlow/'+WFGuid, 'PATCH', JSON.stringify(formDataObj));
            props.setSelectedRequestData(jsondata.Request);
        }
    }

    const WFStepClose = async (event) => {
        const WFGuid = event.currentTarget.getAttribute("wfguid");

        setSelectedWFData(props.RequestData.RequestWF.find(({ Guid }) => Guid === WFGuid));
        quickCloseInitModal();
        //const formDataObj = {action:'close'};
        //let {resultVariant, resultVisible, resultText, jsondata} = await CallAPI('/api/WorkFlow/'+WFGuid, 'PATCH', JSON.stringify(formDataObj));
        //props.setSelectedRequestData(jsondata.Request);
    }

    const WFDatehandleChange = async (event) => {
        const WFGuid = event.target.getAttribute("wfguid");
        const value = event.target.value;
        const formDataObj = {action:'changedate'};
        formDataObj.date = ConcatLocalDateAndTime2ISO(event.target.value);
        let {resultVariant, resultVisible, resultText, jsondata} = await CallAPI('/api/WorkFlow/'+WFGuid, 'PATCH', JSON.stringify(formDataObj));
    }
    
    const WFStepUp = async (event) => {
        const WFGuid = event.currentTarget.getAttribute("wfguid");
        const formDataObj = {action:'moveup'};
        let {resultVariant, resultVisible, resultText, jsondata} = await CallAPI('/api/WorkFlow/'+WFGuid, 'PATCH', JSON.stringify(formDataObj));
        props.setSelectedRequestData(jsondata.Request);
    }  

    const WFStepDown = async (event) => {
        const WFGuid = event.currentTarget.getAttribute("wfguid");
        const formDataObj = {action:'movedown'};
        let {resultVariant, resultVisible, resultText, jsondata} = await CallAPI('/api/WorkFlow/'+WFGuid, 'PATCH', JSON.stringify(formDataObj));
        props.setSelectedRequestData(jsondata.Request);
    }
  
    const WFStepEdit = async (event) => {
        const WFGuid = event.currentTarget.getAttribute("wfguid");

        setSelectedWFData(props.RequestData.RequestWF.find(({ Guid }) => Guid === WFGuid));
        editWFStepInitModal();
    } 

    var typingTimeout = null;

	function handleChange(event){
		if (props.RequestData.Guid!="00000000-0000-0000-0000-000000000000") {
			clearTimeout(typingTimeout);
			typingTimeout = setTimeout(handleSave, 475);
		}
	}

	function handleChangeContact(event){
		if (props.RequestData.Guid!="00000000-0000-0000-0000-000000000000") {

            if (window.confirm("Are you sure that you want to change contact on the request?")) {
                clearTimeout(typingTimeout);
                typingTimeout = setTimeout(handleSave, 475);
            } else {
                document.getElementById('ContactID').value = props.RequestData.ContactID;
            }
		}
	}    

	async function handleSave(){
		setSaving(true);
		setResultText("");
		setResultVisible(false);
		const ProductForm = document.getElementById('RequestForm');
		const formData = new FormData(ProductForm);
		const formDataObj = Object.fromEntries(formData.entries());
		formDataObj.expected_delivery = ConcatLocalDateAndTime2ISO(document.getElementById('expected_delivery').value);
			let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPI('/api/requests/'+props.RequestData.Guid,"PUT",JSON.stringify(formDataObj));
			if (resultVariant!="success") {
				setResultVariant(resultVariant);   
				setResultText(resultText);
				setResultVisible(resultVisible);
			} else {
                props.setSelectedRequestData(jsondata.Request);
            }
		setSaving(false);
	}    

    const handleUpload = async (e) => {
		e.preventDefault();
		const url = '/api/Files/UploadFiles/'+props.RequestData.Guid;
		const FrmUpload = document.getElementById('FrmUpload');
		const formData = new FormData(FrmUpload);
	  
		const fetchOptions = {
		  method: 'post',
		  body: formData
		};
	  
		const response = await fetch(url, fetchOptions);

		if (response.ok) {
			const jsondata = await response.json();
			props.setSelectedRequestData(jsondata.Request);

			FrmUpload.reset();
		} else {
			throw response;
		}
	};

	const RemoveFile = async (event) => {
		event.preventDefault();
		const fileGuid = event.currentTarget.getAttribute("fileGuid");
		const url = '/api/Files/'+fileGuid;

		let {resultVariant, resultVisible, resultText, errorsException, jsondata} = await CallAPI(url,'DELETE');
		props.setSelectedRequestData(jsondata.Request);
	};

    return (
        <>
                <Modal show={addWFStepModalshow} onHide={addWFStepInitModal} size='xl' style={{zIndex: 1510, backgroundColor: '#aaa'}}>
                    <Modal.Header closeButton>
                        <Modal.Title>New step</Modal.Title>
                    </Modal.Header>			
                    <Modal.Body>
                        <Form id='AddWFForm' name='AddWFForm'>
                            <Form.Group className="mb-3" >
                                <Form.Select name="requestWFItemID" id="requestWFItemID">
                                    {getOptions(props.MetaData.RequestWFItems)}
                                </Form.Select>
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc01</Form.Label>								
                                <Form.Control type="text" name="misc01" id="newstep_misc01" placeholder="" />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc02</Form.Label>								
                                <Form.Control type="text" name="misc02" id="newstep_misc02" placeholder="" />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc03</Form.Label>								
                                <Form.Control type="text" name="misc03" id="newstep_misc03" placeholder="" />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc04</Form.Label>								
                                <Form.Control type="text" name="misc04" id="newstep_misc04" placeholder="" />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc05</Form.Label>								
                                <Form.Control type="text" name="misc05" id="newstep_misc05" placeholder="" />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label>Estimated Time (Minutes)</Form.Label>								
                                <Form.Control type="number" name="estimatedTime" id="estimatedTime" placeholder="" defaultValue="0" />
                            </Form.Group>                            
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Assignee</Form.Label>
                                <Form.Select name="assignee_id" id="assignee_id">
                                    <option value={0}>----------</option>
                                    {getOptions(props.MetaData.Users)}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3"  hidden={!saving}>
                                <SpinnerX  />
                            </Form.Group>                                      
                            
                            <Form.Group className="mb-3"  hidden={!resultVisible}>
                                <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                            </Form.Group> 	                                                 
    
                            <Form.Group className="mb-3" >
                                <Button className="QmeButton" variant="primary" type="button" onClick={() => {WFStepAdd()} } >Add</Button>
                            </Form.Group>						
                        </Form>	
                    </Modal.Body>                
                </Modal>

                <Modal show={quickCloseStepModalshow} onHide={quickCloseInitModal} size='xl'   style={{zIndex: 1510, backgroundColor: '#ccc'}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Quick Close</Modal.Title>
                    </Modal.Header>			
                    <Modal.Body>
                        <Form id='QuickCloseForm' name='QuickCloseForm'>
                            <Form.Group className="mb-3" >
                                <Form.Label>Estimated Time (Minutes)</Form.Label>								
                                <Form.Control type="number" name="estimatedTime" id="estimatedTime" placeholder="" defaultValue={selectedWFData.estimatedTime} disabled />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Actual Time (Minutes)</Form.Label>								
                                <Form.Control type="number" name="actualTime" id="actualTime" placeholder=""  defaultValue={selectedWFData.actualTime} />
                            </Form.Group>

                                      <Alert>
                                        <Alert.Heading>Actual Time (Minutes)</Alert.Heading>
                                          •	Fyll i tiden det tog i verkligheten.<br></br>
                                          •	Om tex 2 personer jobbat i 25 minuter, fyll i 50 minuter.<br></br>                                          
                                      </Alert>                                        																						
    
                            <Form.Group className="mb-3" >  
                                <Button className="QmeButton" variant="primary" type="button" onClick={() => { WFStepQuickClose()} } >Save</Button>
                            </Form.Group>						
                        </Form>	
                    </Modal.Body>
                </Modal>	                
  
                <Modal show={editWFStepModalshow} onHide={editWFStepInitModal} size='xl'   style={{zIndex: 1510, backgroundColor: '#ccc'}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update</Modal.Title>
                    </Modal.Header>			
                    <Modal.Body>
                        <Form id='EditWFForm' name='EditWFForm'>
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc01</Form.Label>								
                                <Form.Control as="textarea" rows="1" type="text" name="misc01" id="misc01" placeholder="" defaultValue={selectedWFData.misc01} />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc02</Form.Label>								
                                <Form.Control as="textarea" rows="1" type="text" name="misc02" id="misc02" placeholder="" defaultValue={selectedWFData.misc02} />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc03</Form.Label>								
                                <Form.Control as="textarea" rows="1" type="text" name="misc03" id="misc03" placeholder="" defaultValue={selectedWFData.misc03} />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc04</Form.Label>								
                                <Form.Control as="textarea" rows="1" type="text" name="misc04" id="misc04" placeholder="" defaultValue={selectedWFData.misc04} />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Desc05</Form.Label>								
                                <Form.Control as="textarea" rows="1" type="text" name="misc05" id="misc05" placeholder=""  defaultValue={selectedWFData.misc05} />
                            </Form.Group>

                            <Form.Group className="mb-3" >
                                <Form.Label>Estimated Time (Minutes)</Form.Label>								
                                <Form.Control type="number" name="estimatedTime" id="estimatedTime" placeholder="" defaultValue={selectedWFData.estimatedTime} />
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Actual Time (Minutes)</Form.Label>								
                                <Form.Control type="number" name="actualTime" id="actualTime" placeholder=""  defaultValue={selectedWFData.actualTime} />
                            </Form.Group>                            
    
                            <Form.Group className="mb-3" >
                                <Form.Label>State</Form.Label>
                                <Form.Select name="RequestsWFStateItemID" id="RequestsWFStateItemID" defaultValue={selectedWFData.RequestsWFStateItemID}>
                                    {getOptions(props.MetaData.RequestWFStateItems)}
                                </Form.Select>
                            </Form.Group>
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Date:</Form.Label>								
                                <Form.Control type="date" name="Date" id="Date" defaultValue={SplitoutLocalDateFromISO(selectedWFData.Date)} />
                            </Form.Group>  						
    
                            <Form.Group className="mb-3" >
                                <Form.Label>Assignee</Form.Label>
                                <Form.Select name="assignee_id" id="assignee_id"  defaultValue={selectedWFData.assignee_id}>
                                    <option value={0}>----------</option>
                                    {getOptions(props.MetaData.Users)}
                                </Form.Select>
                            </Form.Group>                        																						
    
                            <Form.Group className="mb-3" >
                                <Button className="QmeButton" variant="primary" type="button" onClick={() => {WFStepUpdate()} } >Save</Button>
                            </Form.Group>						
                        </Form>	
                    </Modal.Body>
                </Modal>			
  
                <Modal show={props.show} onHide={props.fn} size='xl' style={{zIndex: 1500, backgroundColor: '#ddd'}}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.RequestData.Title} ({props.RequestData.Identifier})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Form id='RequestForm' name='RequestForm'>
    
                        <Form.Group className="mb-3" >
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" name="Title" id="Title" placeholder="Title"  defaultValue={props.RequestData.Title} onChange={handleChange} />
                        </Form.Group>
    
                        <Form.Group className="mb-3" >
                            <Form.Label>Beskrivning</Form.Label>								
    
                            <Form.Control as="textarea" rows="3" type="text" name="Description" id="Description" placeholder="Produktbeskrivning" defaultValue={props.RequestData.Description} onChange={handleChange} />
                        </Form.Group>  
    
                        <Form.Group className="mb-3" >
                            <Form.Label>Customer ref:</Form.Label>								
                            <Form.Control type="text" name="Custref" id="Custref" placeholder="Customer ref" defaultValue={props.RequestData.Custref} onChange={handleChange} />
                        </Form.Group>
    
                        <Form.Group className="mb-3" >
                            <Form.Label>Expected delivery:</Form.Label>								
                            <Form.Control type="date" name="expected_delivery" id="expected_delivery" placeholder="Expected delivery" defaultValue={SplitoutLocalDateFromISO(props.RequestData.expected_delivery)} onChange={handleChange} />
                        </Form.Group>                        
    
                        <Form.Group className="mb-3" >
                            <Form.Label>Priority</Form.Label>
                            <Form.Select name="RequestPriorityItemId" id="RequestPriorityItemId" defaultValue={props.RequestData.Guid=="00000000-0000-0000-0000-000000000000" ? (props.MetaData.RequestPriorityItems.find((element) => element.Default == true).Id):(props.RequestData.RequestPriorityItemID)} onChange={handleChange}>
                                {getOptions(props.MetaData.RequestPriorityItems)}
                            </Form.Select>
                        </Form.Group>
    
                        <Form.Group className="mb-3" >
                            <Form.Label>State</Form.Label>
                            <Form.Select name="RequestStateItemID" id="RequestStateItemID" defaultValue={props.RequestData.Guid=="00000000-0000-0000-0000-000000000000" ? (10):(props.RequestData.RequestStateItemID)} onChange={handleChange}>
                                {getOptions(props.MetaData.RequestStateItems)}
                            </Form.Select>
                        </Form.Group>
                        
                        <Form.Group className="mb-3" >
                            <Form.Label>Assignee</Form.Label>
                            <Form.Select name="UserID" id="UserID" defaultValue={props.RequestData.Guid=="00000000-0000-0000-0000-000000000000" ? (props.MetaData.Users.find((element) => element.Default == true).Id):(props.RequestData.UserID)} onChange={handleChange}>
                            <option value={0}>----------</option>
                                {getOptions(props.MetaData.Users)}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" >
                              <Form.Label>Contact</Form.Label>
                              <Form.Select name="ContactID" id="ContactID" defaultValue={props.RequestData.ContactID} onChange={handleChangeContact} on>
                                  <option value={0}>----------</option>
                                  {getOptions(props.MetaData.Contacts)}
                              </Form.Select>
                              <Form.Label style={{fontSize: '10px'}}><div style={{margin: "0 10px"}}>{props.contactData.orgnr} {props.contactData.eMail} {props.contactData.phonenumber}<br />{props.contactData.street01} {props.contactData.street02} {props.contactData.zip} {props.contactData.city}</div></Form.Label>
                        </Form.Group>	                        
	
  
                      <Form.Group className="mb-3" >
                          <Accordion defaultActiveKey="0" >
                              <Accordion.Item eventKey="0">
                                  <Accordion.Header>Workflow ({props.RequestData.RequestWF.length})</Accordion.Header>
                                  <Accordion.Body>
  
                                      <Table responsive="sm">
                                      <thead>
                                          <tr><th>Task.Name</th><th>Desc1</th><th>Desc2</th><th>Desc3</th><th>Desc4</th><th>Desc5</th><th>Date</th><th>Assignee</th><th>State</th><th>Action</th></tr>
                                      </thead>
                                      <tbody>
                                          {/* {props.RequestData.RequestWF.sort((a, b) => a.workorder - b.workorder).map((state) => {  */}
                                          {props.RequestData.RequestWF.map((state) => { 
                                          return <tr key={props.MetaData.Id}>
                                              <td>{metaLookUpTitle(props.MetaData.RequestWFItems,state.RequestWFItemID)}</td>
                                              <td>{state.misc01}</td><td>{state.misc02}</td><td>{state.misc03}</td><td>{state.misc04}</td><td>{state.misc05}</td>
                                              {/* <td>{SplitoutLocalDateFromISO(state.Date)}</td> */}
                                              <td><input type='date' wfguid={state.Guid} onChange={WFDatehandleChange} defaultValue={SplitoutLocalDateFromISO(state.Date)}></input></td>
                                              <td>{metaLookUpTitle(props.MetaData.Users,state.assignee_id)}</td>
                                              <td>{metaLookUpTitle(props.MetaData.RequestWFStateItems,state.RequestsWFStateItemID)}</td>
                                              <td style={{whiteSpace:"nowrap"}}>
                                                  <span className='sub' wfguid={state.Guid} onClick={WFStepEdit}><FaEdit title="Edit step"/></span>&nbsp;
                                                  <span className='sub' wfguid={state.Guid} onClick={WFStepUp} ><FaSortUp title="Move up" /></span>&nbsp;
                                                  <span className='sub' wfguid={state.Guid} onClick={WFStepDown}><FaSortDown title="Move down" /></span>&nbsp;
                                                  <span className='sub' wfguid={state.Guid} onClick={WFStepRemove}><FaEraser title="Delete step" /></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                                  <span className='sub' wfguid={state.Guid} onClick={WFStepClose}><FaCheck title="Snabbavslut" /></span>
                                              </td>
                                          </tr> })}
                                      </tbody>
                                      </Table>
                                      <Button className="QmeButton" variant="primary" type="button" onClick={() => { addWFStepInitModal(); } } >Add step</Button>
                                  </Accordion.Body>
                              </Accordion.Item>
                          </Accordion> 
                      </Form.Group>
  
                      {/* <Form.Group className="mb-3" >
                      <Accordion>
                          <Accordion.Item eventKey="0">
                              <Accordion.Header>Relations  ({props.RequestData.RequestChilds.length})</Accordion.Header>
                              <Accordion.Body>
                                  <Table responsive="sm">
                                      <thead>
                                          <tr><th>Id</th><th>Title</th><th>State</th><th>Assignee</th><th>Action</th></tr>
                                          {props.RequestData.RequestChilds.map((state) => { 
                                          return <tr>
                                          <td>{state.RequestId}</td>
                                          <td>{state.filetype} ({state.fileext})</td>
                                          <td>{metaLookUpTitle(metadata.Users,state.UserID)}</td>
                                          </tr> })}
                                      </thead>
                                  </Table>
                                  <Button className="QmeButton" variant="primary" type="submit">Connect request</Button>
                              </Accordion.Body>
                          </Accordion.Item>
                      </Accordion>
                      </Form.Group> */}
  
                      <Form.Group className="mb-3" >
                      <Accordion>
                          <Accordion.Item eventKey="0">
                              <Accordion.Header>Files ({props.RequestData.RequestFiles.length})</Accordion.Header>
                              <Accordion.Body>
                                  <Table responsive="sm">
                                      {/* 21307 */}
                                      <thead> 
                                          <tr><th>Filename</th><th>Filetype</th><th>User</th><th></th></tr>
                                          {props.RequestData.RequestFiles.map((state) => { 
                                          return <tr>
                                          <td>{state.FileName}</td>
                                          <td>{state.filetype} ({state.fileext})</td>
                                          <td>{metaLookUpTitle(props.MetaData.Users,state.UserID)}</td>
                                          <td><a href={"/files/"+state.Guid+""+state.fileext}><FaDownload title="Download" /></a>&nbsp;<span onClick={RemoveFile} fileGuid={state.Guid}><FaEraser title="Delete" /></span></td>										
                                          </tr> })}
                                      </thead>
                                  </Table>
  
                                  <>
                                      <div className="input-group">
                                          <form id="FrmUpload"  method="post" enctype="multipart/form-data">
                                              <input name="file" id="file" type="file" />
                                              <button onClick={handleUpload} type="button">Ladda upp</button>
                                          </form>
                                      </div>
                                  </>
  
                                  {/* <Button className="QmeButton" variant="primary" type="button" onclick={() => alert('Datamigrering som påverkar funktionen pågår fortfarande!')}>Upload</Button> */}
                              </Accordion.Body>
                          </Accordion.Item>
                      </Accordion>  
                      </Form.Group>
      
                      {/* <Form.Group className="mb-3" >
                          <Accordion >
                              <Accordion.Item eventKey="0">
                                  <Accordion.Header>Diary/Log ({props.RequestData.RequestLog.length})</Accordion.Header>
                                  <Accordion.Body >
  
                                      <Table responsive="sm">
                                          <thead>
                                              <tr> <th>Created</th> <th>Comment</th> <th>User</th> </tr>
                                              {props.RequestData.RequestLog.map((state) => { 
                                              return <tr>
                                              <td>{SplitoutLocalDateFromISO(state.Created)}</td>
                                              <td>{state.Comment}</td>
                                              <td>{metaLookUpTitle(metadata.Users,state.UserID)}</td>
                                              </tr> })}
                                          </thead>
                                      </Table>
                                  </Accordion.Body>
                              </Accordion.Item>
                          </Accordion>                  
                      </Form.Group> */}
  
                      <Form.Group className="mb-3"  hidden={!saving}>
                          <SpinnerX  />
                      </Form.Group>                                      
                      
                      <Form.Group className="mb-3"  hidden={!resultVisible}>
                          <Alert show={resultVisible} variant={resultVariant}  className="FormSpace">{resultText}</Alert> 
                      </Form.Group> 												 													
                  </Form>  
                                      
                  </Modal.Body>
                  <Modal.Footer>

                  <Button variant="danger" onClick={() => { props.fn(); setNewRequestModalshow(true); }}>Copy to new request</Button>
                  <Button variant="danger" onClick={props.fn}>Close</Button>
                  </Modal.Footer>
              </Modal>  

              <CreateRequest MetaData={props.MetaData} DefaultValues={props.RequestData} show={newRequestModalshow} CreateRequestToggle={ ()=> { setNewRequestModalshow(!newRequestModalshow); } } contactData={props.contactData} />
        </>
      );      
};